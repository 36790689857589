// do sentry first, to catch any errors
import initSentry from './utils/sentry.js';
initSentry();

import '@picocss/pico/css/pico.min.css';
import { signInWithGoogle, signInWithMicrosoft, signInWithPassword, signInWithToken } from './utils/providers';
import { checkSessionValidity, fetchFirebaseToken, logout } from './utils/session';
import { checkForCodeGeneration } from './code';
import { createAppGridItem } from './utils/ui';
import { loadSettings } from './settings';
import { showError } from './utils/error';
import { validateEmail } from './utils/email.js';

// Helper function to update submit button state
function updateSubmitButton(email, password) {
    const submitButton = document.getElementById('login-pw-submit');

    if (email && password && validateEmail(email)) {
        submitButton.removeAttribute('disabled');
    } else {
        submitButton.setAttribute('disabled', 'disabled');
    }
}

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

window.user = null;
window.loginComplete = false;

export let API_HOST = 'https://api.mgka.net';
if (window.location.hostname === '127.0.0.1' || window.location.hostname === '127.0.0.1') {
    console.warn('Using local API');
    API_HOST = 'http://127.0.0.1:5001/slam4edu/us-central1/api';
}

// Helper functions to manage button states
const loginButtons = ['login-google', 'login-msft', 'login-pw', 'login-pw-submit', 'login-pw-sso'];

const setLoginInProgress = (activeButtonId, isLoading) => {
    // Set all buttons' disabled state
    loginButtons.forEach(buttonId => {
        const button = document.getElementById(buttonId);
        if (button) {
            button.disabled = isLoading;
        }
    });

    // Set aria-busy only on the active button
    if (activeButtonId) {
        const activeButton = document.getElementById(activeButtonId);
        if (activeButton) {
            activeButton.setAttribute('aria-busy', isLoading);
        }
    }
};

const handleLoginAttempt = async (buttonId, loginFn, errorMessage) => {
    setLoginInProgress(buttonId, true);
    try {
        await loginFn();
        await checkForRedirect();
        await checkForCodeGeneration();
        successfulLogin();
    } catch (error) {
        console.error(error);
        showError(errorMessage, 'Please try again later.');
    } finally {
        setLoginInProgress(null, false);
    }
};


// configure event listeners for buttons etc
async function configureEventListeners() {
    // Google login handler
    document.getElementById('login-google').addEventListener('click', async () => {
        await handleLoginAttempt(
            'login-google',
            signInWithGoogle,
            'There was an error logging in with Google.'
        );
    });

    // Microsoft login handler
    document.getElementById('login-msft').addEventListener('click', async () => {
        await handleLoginAttempt(
            'login-msft',
            signInWithMicrosoft,
            'There was an error logging in with Microsoft.'
        );
    });

    // Toggle between SSO and password login
    document.getElementById('login-pw').addEventListener('click', () => {
        document.getElementById('sso-buttons').style.display = 'none';
        document.getElementById('login-pw-container').style.display = 'block';
    });

    document.getElementById('login-pw-sso').addEventListener('click', () => {
        const form = document.getElementById('login-pw-form');
        form.elements.login.value = '';
        form.elements.password.value = '';
        document.getElementById('login-pw-container').style.display = 'none';
        document.getElementById('sso-buttons').style.display = 'block';
    });

    // Email login form handler
    document.getElementById('login-email').addEventListener('input', (e) => {
        const email = e.target.value;
        const password = document.getElementById('login-password').value;
        updateSubmitButton(email, password);


    });

    // When the email field loses focus, check the validity of the email
    document.getElementById('login-email').addEventListener('blur', (e) => {
        const email = e.target.value;
        const password = document.getElementById('login-password').value;
        updateSubmitButton(email, password);

        // if invalid, red border
        if (email && !validateEmail(email)) {
            document.getElementById('login-email').style.border = '1px solid red';
        } else {
            document.getElementById('login-email').style.border = '';
        }
    });

    // Password login form handler
    document.getElementById('login-password').addEventListener('input', (e) => {
        const email = document.getElementById('login-email').value;
        const password = e.target.value;
        updateSubmitButton(email, password);
    });

    // When the password field loses focus, check the validity of the email
    document.getElementById('login-password').addEventListener('blur', (e) => {
        const email = document.getElementById('login-email').value;
        const password = e.target.value;
        updateSubmitButton(email, password);
    });

    // Password login form handler
    document.getElementById('login-pw-form').addEventListener('submit', async (e) => {
        e.preventDefault();
        const form = e.target;

        setLoginInProgress('login-pw-submit', true);
        try {
            await signInWithPassword(form.elements.login.value, form.elements.password.value);
            await checkForRedirect();
            await checkForCodeGeneration();
            successfulLogin();

            // Reset and hide form
            document.getElementById('login-pw-container').style.display = 'none';
            form.elements.login.value = '';
            form.elements.password.value = '';
        } catch (error) {
            console.error(error);
            // firebase auth/invalid-credential - show error
            if (error.code === 'auth/invalid-credential') {
                showError('Your email or password is incorrect.', 'Please try again.');
            } else {
                showError('There was an error logging in with email and password.', 'Please try again later.');
            }
        } finally {
            setLoginInProgress(null, false);
        }
    });

    // settings page button
    document.getElementById('settings-btn').addEventListener('click', async () => {
        document.getElementById('settings-btn').setAttribute('aria-busy', 'true');

        await loadSettings().then(async () => {
            document.getElementById('settings-btn').setAttribute('aria-busy', 'false');
            document.getElementById('settings').style.display = 'block';
            document.getElementById('home').style.display = 'none';
        }).catch((error) => {
            console.error(error);
            document.getElementById('setting-btn').setAttribute('aria-busy', 'false');
            showError('There was an error your account settings.', 'Please try again later.');
        });

    });

    // home page button
    document.getElementById('home-btn').addEventListener('click', async () => {
        document.getElementById('settings').style.display = 'none';
        document.getElementById('home').style.display = 'block';
    });

    // logout button
    document.getElementById('logout-btn').addEventListener('click', async () => {
        document.getElementById('logout-btn').setAttribute('aria-busy', 'true');
        await logout();
        document.getElementById('logout-btn').setAttribute('aria-busy', 'false');
    });

    // Check session validity on page focus & blur
    window.addEventListener('focus', async () => {
        await checkSessionValidity();
    });
    window.addEventListener('blur', async () => {
        await checkSessionValidity();
    });
}

document.addEventListener('DOMContentLoaded', async () => {
    console.log('hello world!');
    await configureEventListeners();

    console.log('Checking for existing session...');

    await fetchFirebaseToken().then(async (token) => {
        console.log('Existing session found');

        await signInWithToken(token).then(async () => {
            await checkForRedirect(); // check first, may be no need to display user info on this page
            await checkForCodeGeneration();
            successfulLogin();
        }).catch((error) => {
            console.log('Error signing in with token:', error);
            // need to re-auth - hide the progress, show the buttons
            document.getElementById('login-progress').style.display = 'none';
            document.getElementById('login-options').style.display = 'block';
        });

    }).catch((error) => {
        console.log('No existing session found:', error);
        // no existing session - show the buttons
        document.getElementById('login-progress').style.display = 'none';
        document.getElementById('login-options').style.display = 'block';
    });
});

// check for a redirect param to go back to the app
async function checkForRedirect() {
    console.log('Checking for redirect...');
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');
    if (redirect) {
        window.location.href = redirect;
    }
}

// do something here in the future
async function successfulLogin() {
    if (window.generatingCode) {
        return; // don't load the app yet
    }
    console.log('Successful login');

    // set the name - eventually all users created will have a display name, currently username/password users do not
    document.getElementById('hi-name').innerText = window.user.displayName ? window.user.displayName : 'Your Account';
    // set home-title based on time of day - first word of name
    const timeOfDay = new Date().getHours();
    if (timeOfDay < 12) {
        // depending on if there is a name
        document.getElementById('home-title').innerText = window.user.displayName ? `Good morning, ${window.user.displayName.split(' ')[0]}` : 'Good morning';
    } else if (timeOfDay < 17) {
        document.getElementById('home-title').innerText = window.user.displayName ? `Good afternoon, ${window.user.displayName.split(' ')[0]}` : 'Good afternoon';
    } else {
        document.getElementById('home-title').innerText = window.user.displayName ? `Good evening, ${window.user.displayName.split(' ')[0]}` : 'Good evening';
    }

    // Ensure the user object is defined and has a photoURL
    if (window.user && window.user.photoURL) {
        document.getElementById('user-pfp').src = new URL(window.user.photoURL).href;
    } else {
        console.log('User or photoURL not defined.');
        document.getElementById('user-pfp').style.display = 'none';
    }

    // load the app
    document.getElementById('auth').style.display = 'none';
    document.getElementById('app').style.display = 'block';

    // testing!
    createAppGridItem('Inscribe', new URL('../img/inscribe.svg', import.meta.url).href, 'https://inscribe.mgka.net');
    createAppGridItem('Protego', new URL('../img/protego.svg', import.meta.url).href, 'https://protego.mgka.net');
    createAppGridItem('Catalyst', new URL('../img/catalyst.svg', import.meta.url).href, 'https://catalyst.mgka.net');
    createAppGridItem('Obviate', new URL('../img/obviate.png', import.meta.url).href, 'https://obviate.mgka.net');
    createAppGridItem('Kitlist', new URL('../img/kitlist.svg', import.meta.url).href, 'https://kitlist.mgka.net');
    createAppGridItem('Aditus', new URL('../img/aditus.svg', import.meta.url).href, 'https://aditus.mgka.net');
    createAppGridItem('Harbour', new URL('../img/harbour.svg', import.meta.url).href, 'http://127.0.0.1:1235');
    createAppGridItem('Visage', new URL('../img/visage.svg', import.meta.url).href, 'https://visage.mgka.net');

    // Synapse example logos
    createAppGridItem('Synapse', new URL('../img/synapse.svg', import.meta.url).href, 'https://synapse.mgka.net');

    window.loginComplete = true;
}