import { API_HOST } from './index.js';

window.generatingCode = false;

// if code param, we nede to generate a code
export async function checkForCodeGeneration() {
    console.log('Checking for code generation...');
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');
    if (code) {
        window.generatingCode = true;
        await generateCode();
    }
}

async function generateCode() {
    console.log('Generating code...');

    // API request to /generate-code - uses the cookie to identify the user
    const response = await fetch(`${API_HOST}/auth/generate-code`, {
        method: 'GET',
        credentials: 'include', // Ensure cookies are sent and received
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data);

    const code = data.code;
    const expiryTime = data.expiryTime; // in milliseconds

    // hide progress, show code in message
    document.getElementById('login-options').style.display = 'none';
    document.getElementById('login-progress').style.display = 'none';
    document.getElementById('message').style.display = 'block';

    // Set initial message content
    document.getElementById('message').innerHTML = `<p>Login Code: <strong>${code}</strong></p><br>This code is valid for ${Math.floor((expiryTime - Date.now()) / 1000)} seconds.`;
    document.getElementById('copy-code').style.display = 'block';

    // count down the seconds until expiry
    const countDown = setInterval(async () => {
        const currentTime = Date.now();
        const timeLeft = Math.floor((expiryTime - currentTime) / 1000); // calculate seconds left

        if (timeLeft <= 0) {
            document.getElementById('message').innerHTML = '<p>Login Code: <strong>Expired</strong></p>';
            document.getElementById('copy-code').style.display = 'block';
            clearInterval(countDown);
            return;
        }

        try {
            const [valid, error] = await checkCodeValidity(code);
            console.log(`valid: ${valid}`);
            console.log(`error: ${error}`);

            if (!valid) {
                if (error === 'Code already used') {
                    document.getElementById('message').innerHTML = '<p><strong>Login Complete</strong> <span style="color:green; padding-left:5px;">&#10003;</span></p>';
                } else if (error === 'Code expired') {
                    document.getElementById('message').innerHTML = '<p>Login Code: <strong>Expired</strong> <span style="color:red; padding-left:5px;">&#10007;</span></p>';
                } else if (error === 'New code generated') {
                    // bad - can only gen one code at a time
                    document.getElementById('message').innerHTML = '<p><strong>Error:</strong> Another code has been generated. <span style="color:red; padding-left:5px;">&#10007;</span></p>';
                }
                else {
                    document.getElementById('message').innerHTML = `<p>Login Code: <strong>Error</strong></p><br>${error}`;
                }
                document.getElementById('copy-code').style.display = 'none';
                document.getElementById('copy-code-link').style.display = 'none';
                clearInterval(countDown);
                return;
            }

        } catch (error) {
            document.getElementById('message').innerHTML = `<p>Login Code: <strong>Error</strong></p><br>${error.message}`;
            document.getElementById('copy-code').style.display = 'block';
            clearInterval(countDown);
            return;
        }

        document.getElementById('message').innerHTML = `<p>Login Code: <strong>${code}</strong></p><br>This code is valid for ${timeLeft} seconds.`;
        document.getElementById('copy-code').style.display = 'block';
    }, 1000);

    // show copy-code
    document.getElementById('copy-code-link').addEventListener('click', async () => {
        await navigator.clipboard.writeText(code);
        document.getElementById('copy-code-link').innerHTML = 'Copied!';
        setTimeout(() => {
            document.getElementById('copy-code-link').innerHTML = 'Copy';
        }, 1500);
    });

}


// API req to /check-code-valid - uses the cookie to identify the user
async function checkCodeValidity(code) {
    console.log('Checking code validity...');

    // API request to /check-code-valid - uses the cookie to identify the user
    const response = await fetch(`${API_HOST}/auth/check-code-valid`, {
        method: 'POST',
        credentials: 'include', // Ensure cookies are sent and received
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code })
    });

    const data = await response.json();

    if (!response.ok) {
        return [data.valid, data.error];
    }

    return [data.valid, data.error];
}