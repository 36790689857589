import { Modal } from '@magickainteractive/modals';
import { changePassword } from './utils/security';
import { linkWithProvider, unlinkProvider } from './utils/providers';
import { showError } from './utils/error';
import { delay } from '.';
import { loadActiveSessions } from './utils/session';

let settingsLoaded = false;

function formatProvider(provider) {
    switch (provider) {
    case 'google.com':
        return 'Google';
    case 'microsoft.com':
        return 'Microsoft';
    case 'password':
        return 'Email & Password';
    default:
        return provider;
    }
}

function addLinkProviderButton(provider) {
    const button = document.createElement('button');
    button.id = `link-${provider}`;
    button.classList.add('btn', 'btn-primary');
    button.textContent = formatProvider(provider);
    document.getElementById('link-providers').appendChild(button);

    button.addEventListener('click', async () => {
        await linkWithProvider(provider).catch(error => {
            console.error(error);
            showError('There was an error linking your account.', 'Please try again later.');
        });
        await delay(1000); // Wait for the link to complete
        await loadSettings(true);
    });
}

// settings page
export async function loadSettings(override = false) {
    if (settingsLoaded && !override) {
        return;
    }
    console.log('Loading settings...');
    // Populate user details
    document.getElementById('settings-name').textContent = window.user.displayName || 'User Name';
    document.getElementById('settings-email').textContent = window.user.email;
    document.getElementById('settings-pic').src = window.user.photoURL || new URL('../img/user.svg', import.meta.url).href;
    // `Provider Name - unlink`
    document.getElementById('settings-provider').textContent = formatProvider(window.user.providerData[0].providerId);

    // If any of the user's providers are password-based, show the password section
    console.log(window.user.providerData);
    window.user.providerData.forEach(provider => {
        if (provider.providerId === 'password') {
            document.getElementById('password-section').style.display = 'block';
            document.getElementById('no-password-section').style.display = 'none';

            // Handle Change Password
            document.getElementById('change-password-btn').addEventListener('click', () => {
                // show a modal with a form to change the password
                const modal = new Modal({
                    title: 'Change Password',
                    content: `
                        <form id="change-password-form" onsubmit="return false;">
                            <fieldset>
                                <input name="currentPassword" placeholder="Current Password" aria-label="Current Password" autocomplete="current-password" type="password" required />
                                <input name="newPassword" placeholder="New Password" aria-label="New Password" autocomplete="new-password" type="password" required />
                            </fieldset>
                        </form>
                        <p id="change-password-error" style="display: none; color: red;"></p>
                    `,
                    actions: [
                        {
                            label: 'Cancel',
                            color: 'gray',
                            action: () => modal.close()
                        },
                        {
                            label: 'Change Password',
                            color: 'primary',
                            animateWhileRunning: true,
                            action: async () => {
                                document.getElementById('change-password-error').style.display = 'none';
                                await changePassword().then(() => {
                                    // success - close the modal
                                    modal.close();
                                }).catch((error) => {
                                    // error - show the error message
                                    console.error(error);
                                    document.getElementById('change-password-error').textContent = 'There was an error changing your password. Please try again.';
                                    document.getElementById('change-password-error').style.display = 'block';
                                });
                            }
                        }
                    ]
                });
                modal.open();
            });
        }
    });

    // clear any existing buttons
    document.getElementById('link-providers').innerHTML = '';
    // Add provider buttons
    let addedProviders = 0;
    // Add Google provider, if not already added
    if (!window.user.providerData.some(p => p.providerId === 'google.com')) {
        addLinkProviderButton('google.com');
        addedProviders++;
    }

    // Add Microsoft provider, if not already added
    if (!window.user.providerData.some(p => p.providerId === 'microsoft.com')) {
        addLinkProviderButton('microsoft.com');
        addedProviders++;
    }

    // if no providers added, hide the section
    if (addedProviders === 0) {
        document.getElementById('link-providers-details').style.display = 'none';
    } else {
        document.getElementById('link-providers-details').style.display = 'block';
    }

    // Password status
    document.getElementById('password-status').textContent = window.user.providerData.some(p => p.providerId === 'password') ? 'Set' : 'Not Set';

    // Linked accounts
    const linkedAccountsList = document.getElementById('linked-accounts');
    linkedAccountsList.innerHTML = '';
    // if only one provider, hide the list
    if (window.user.providerData.length === 1) {
        linkedAccountsList.style.display = 'none';
        document.getElementById('linked-accounts-header').style.display = 'none';
    } else {
        linkedAccountsList.style.display = 'block';
        document.getElementById('linked-accounts-header').style.display = 'block';
    }

    window.user.providerData.forEach(provider => {
        const listItem = document.createElement('li');

        // Create the text node for the provider information
        const textNode = document.createTextNode(`${formatProvider(provider.providerId)} (${provider.email}) - `);

        // Create the unlink anchor element
        const unlinkLink = document.createElement('a');
        unlinkLink.href = '#';
        unlinkLink.id = `unlink-${provider.providerId}`;
        unlinkLink.textContent = 'Unlink';

        // Append the text node and unlink link to the list item
        listItem.appendChild(textNode);
        listItem.appendChild(unlinkLink);

        // Append the list item to the list
        linkedAccountsList.appendChild(listItem);

        // Attach the event listener to the unlink link
        unlinkLink.addEventListener('click', async (event) => {
            console.log('Unlinking provider:', provider.providerId);
            event.preventDefault(); // Prevent the default link behavior
            await unlinkProvider(provider.providerId).catch(error => {
                console.error(error);
                showError('There was an error unlinking your account.', 'Please try again later.');
            });
            await delay(1000); // Wait for the unlink to complete
            await loadSettings(true);
        });
    });

    // Load active sessions
    await loadActiveSessions();
    settingsLoaded = true;
}