import { auth } from './firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

// change password
export async function changePassword() {
    return new Promise((resolve, reject) => {
        // get the passwords
        const form = document.getElementById('change-password-form');
        const currentPassword = form.elements.currentPassword.value;
        const newPassword = form.elements.newPassword.value;

        if (currentPassword && newPassword) {
            // reauth to check the current password is valid
            reauthenticateWithCredential(auth.currentUser, EmailAuthProvider.credential(auth.currentUser.email, currentPassword)).then(async () => {
                // all good - update the password
                await updatePassword(auth.currentUser, newPassword).then(() => {
                    console.log('Password changed successfully.');
                    resolve();
                }).catch(error => {
                    console.error('Error updating password:', error);
                    reject('There was an error changing your password.');
                });
            }).catch(error => {
                console.error('Error reauthenticating:', error);
                reject('There was an error changing your password.');
            });
        } else {
            reject('Please enter a current password and new password.');
        }
    });
}