//// Manage sessions across *.mgka.net subdomains
// do sentry first, to catch any errors
import initSentry from './sentry.js';
const Sentry = initSentry();

import { auth } from './firebase.js';
import { API_HOST } from '../index.js';
import UAParser from 'ua-parser-js';
import { showError } from './error.js';

// check if we have an existing active session & get a token for it
export async function fetchFirebaseToken() {
    const response = await fetch(`${API_HOST}/auth/get-token`, {
        method: 'GET',
        credentials: 'include'  // to send the cookie along with the request
    });
    if (response.ok) {
        const data = await response.json();
        return data.firebaseToken;
    } else if (response.status === 401) {
        // session expired, need to re-auth
        console.warn('Session expired, need to re-auth');
        throw new Error('Session expired');
    } else if (response.status === 404) {
        // no session found
        console.warn('No session found');
        throw new Error('No session found');
    } else if (response.status === 500) {
        // server error
        console.error('Server error:', response.statusText);
        throw new Error('Server error');
    }
}

// This will generate the session ID and add as a HttpOnly cookie
export async function postIdTokenToSessionLogin(idToken) {
    const parser = new UAParser();
    const uaResult = parser.getResult();

    const deviceInfo = {
        deviceType: uaResult.device.type || 'Unknown',
        os: uaResult.os.name || 'Unknown',
        osVersion: uaResult.os.version || 'Unknown',
        browser: uaResult.browser.name || 'Unknown',
        browserVersion: uaResult.browser.version || 'Unknown'
    };

    console.log(deviceInfo);

    const response = await fetch(`${API_HOST}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ idToken, deviceInfo })
    });

    if (!response.ok) {
        throw new Error('Unable to login');
    }

    const data = await response.json();
    return data;
}

// Logout - send POST request to /logout and sign out of Firebase
export async function logout(message = 'You have been successfully logged out.', showLoginOptions = false) {
    const response = await fetch(`${API_HOST}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (response.ok && data.status === 'success') {
        // Sign out of Firebase
        window.loginComplete = false;
        await auth.signOut();
        Sentry.setUser(null);

        // Show a logout message
        if (showLoginOptions) {
            document.getElementById('login-options').style.display = 'block';
        } else {
            document.getElementById('login-options').style.display = 'none';
        }

        document.getElementById('login-desc').innerText = message;
        document.getElementById('login-desc').style.display = 'block';
        document.getElementById('login-progress').style.display = 'none';

        document.getElementById('app').style.display = 'none';
        document.getElementById('auth').style.display = 'flex';

        // Refresh after 3 seconds
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    } else {
        throw new Error('Network response was not ok');
    }

}


export async function loadActiveSessions() {
    const sessionsList = document.getElementById('active-sessions-list').querySelector('tbody');
    sessionsList.innerHTML = ''; // Clear existing table rows

    try {
        const sessionResponse = await fetchUserSessions();
        const userSessions = sessionResponse.data || [];
        console.log(userSessions);

        // Hide the section if no sessions are available
        if (userSessions.length === 0) {
            document.querySelector('.settings-sessions').style.display = 'none';
            return;
        }

        userSessions.forEach(session => {
            const row = document.createElement('tr');

            // Add a specific class for the current session
            if (session.thisSession) {
                row.classList.add('this-session');
            }

            // Create cells for each session attribute
            const osCell = document.createElement('td');
            osCell.textContent = `${session.os} ${session.osVersion}`;
            row.appendChild(osCell);

            const browserCell = document.createElement('td');
            browserCell.textContent = `${session.browser} ${session.browserVersion}`;
            row.appendChild(browserCell);

            const timestamp = session.lastActive._seconds * 1000 + Math.floor(session.lastActive._nanoseconds / 1000000);
            const date = new Date(timestamp);
            const lastActiveCell = document.createElement('td');
            lastActiveCell.textContent = date.toLocaleString();
            row.appendChild(lastActiveCell);

            const ipCell = document.createElement('td');
            ipCell.textContent = session.ip ? session.ip : 'Unknown';
            row.appendChild(ipCell);

            const terminateCell = document.createElement('td');
            if (!session.thisSession) {
                const terminateBtn = document.createElement('button');
                terminateBtn.classList.add('btn', 'btn-secondary', 'terminate-session-btn');
                terminateBtn.textContent = 'Terminate';
                terminateBtn.addEventListener('click', async () => {
                    terminateBtn.setAttribute('aria-busy', 'true');
                    await terminateSession(session.__id).catch(error => {
                        console.error(error);
                        showError('There was an error terminating the session.', 'Please try again later.');
                    });
                    terminateBtn.setAttribute('aria-busy', 'false');
                    // remove this session from the list
                    row.remove();
                });
                terminateCell.appendChild(terminateBtn);
            } else {
                row.classList.add('this-session');
                terminateCell.innerHTML = '<i>Current Session</i>';
            }
            row.appendChild(terminateCell);

            sessionsList.appendChild(row);
        });
    } catch (error) {
        console.error('Error loading active sessions:', error);
        showError('Failed to load active sessions.', 'Please try again later.');
    }
}

async function fetchUserSessions() {
    const response = await fetch(`${API_HOST}/auth/sessions`, {
        method: 'GET',
        credentials: 'include'  // to send the cookie along with the request
    });
    if (!response.ok) {
        throw new Error('Failed to fetch sessions');
    }
    return await response.json();
}

async function terminateSession(sessionId) {
    console.log('Terminating session:', sessionId);
    const response = await fetch(`${API_HOST}/auth/terminate-session`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetSession: sessionId }),
    });
    if (!response.ok) {
        throw new Error('Failed to terminate session');
    }
    return response.json().then(data => data.status === 'success');
}

// Check if the current session is still valid
export async function checkSessionValidity() {
    // only if the user is logged in
    if (!window.loginComplete) {
        return null; // null because true/false means the session itself
    }

    const response = await fetch(`${API_HOST}/auth/check-session`, {
        method: 'GET',
        credentials: 'include',
    });

    // Invalid session returns 404, 401, or 500
    if (!response.ok) {
        console.error('Session is no longer valid');
        await logout('Your session has expired. Please log in again.', true);
        return false;
    }

    // Session is valid
    return true;
}