export function createAppGridItem(name, logo, link) {
    const appGrid = document.getElementById('app-grid');

    const gridItem = document.createElement('div');
    gridItem.classList.add('app-grid-item');
    gridItem.style.setProperty('cursor', 'pointer');

    // Create a canvas to analyze the image colors
    const img = new Image();
    img.src = logo;

    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, img.width, img.height);
    
        // Extract colors from the image
        let gradientColors = extractDominantColors(context, img.width, img.height, 3);
    
        // Adjust colors for transparency to lighten or darken them
        gradientColors = gradientColors.map(color => rgbToRgba(color, 0.4)); // Adjust alpha to 0.5 (50% transparency)
    
        // Create the gradient background style string
        const gradientStyle = `linear-gradient(135deg, ${gradientColors.join(', ')})`;
    
        // Apply hover effect with the gradient colors
        gridItem.style.setProperty('--gradient-background', gradientStyle);
    };

    const content = document.createElement('div');
    content.classList.add('app-grid-item-content');

    const title = document.createElement('h4');
    title.textContent = name;

    content.appendChild(img);
    content.appendChild(title);

    gridItem.appendChild(content);
    appGrid.appendChild(gridItem);

    gridItem.addEventListener('click', () => {
        window.open(link, '_blank');
    });
}

function rgbToRgba(rgb, alpha) {
    const rgbArray = rgb.match(/\d+/g);
    return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, ${alpha})`;
}

function extractDominantColors(context, width, height, colorCount = 3) {
    // Get image data from the canvas
    const imageData = context.getImageData(0, 0, width, height);
    const data = imageData.data;

    // Create a map to count occurrences of each color
    const colorMap = {};

    for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const color = `rgb(${r},${g},${b})`;

        if (!colorMap[color]) {
            colorMap[color] = 0;
        }
        colorMap[color]++;
    }

    // Sort colors by frequency
    const sortedColors = Object.entries(colorMap).sort((a, b) => b[1] - a[1]);

    // Extract the top 'colorCount' dominant colors
    const dominantColors = sortedColors.slice(0, colorCount).map(entry => entry[0]);

    // Return the colors to be used in the gradient
    return dominantColors.length > 1 ? dominantColors : ['#000000', '#FFFFFF'];
}