// do sentry first, to catch any errors
import initSentry from './sentry.js';
const Sentry = initSentry();

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCj6olj5kvdGhl32pJlxL8wq20oxIWWmpo',
    authDomain: 'auth.mgka.net',
    projectId: 'slam4edu',
    storageBucket: 'slam4edu.appspot.com',
    messagingSenderId: '640905549057',
    appId: '1:640905549057:web:49c00dd94e299ff448b700'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

auth.onAuthStateChanged(async (user) => {
    if (user) {
        console.log('User signed in');
        Sentry.setUser({ id: user.uid, email: user.email });
    } else {
        console.log('User signed out');
        Sentry.setUser(null);
    }
});

export { app, auth };