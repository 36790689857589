import { Modal } from '@magickainteractive/modals';

// show an error modal
export function showError(title, message) {
    const errorModal = new Modal({
        title: title,
        content: message,
        actions: [
            {
                label: 'Close',
                action: () => {
                    errorModal.close();
                },
                // default color
            },
        ],
    });

    errorModal.open();
}
