import * as Sentry from '@sentry/browser';

export default function init() {
    if (!window.__sentry_initialized__) {
        // doesn't exist, initialise
        console.log('Initialising Sentry');

        // set env depending on hostname
        var environment = 'production';
        if (window.location.hostname === '127.0.0.1' || window.location.hostname === '127.0.0.1') {
            console.log('Using local Sentry environment');
            environment = 'development';
        }

        Sentry.init({
            dsn: 'https://b0154b0617ce0a8d23357c245378f7f5@o4506583309680640.ingest.us.sentry.io/4507810723463168',
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            environment: environment,
            integrations: [
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                new Sentry.BrowserProfilingIntegration(),
                new Sentry.BrowserTracing(),
            ],
            beforeSend(event, hint) {
                console.log('beforeSend', event, hint);
                if (hint && hint.originalException && hint.originalException.message === 'Error: Operation timed out') {
                    // Ignore the timeout error
                    return null;
                }
                // Check if it is an exception, and if so, show the report dialog
                if (event.exception && event.event_id) {
                    Sentry.showReportDialog({ eventId: event.event_id });
                }
                return event;
            },
            tracesSampleRate: 1.0,
            profilesSampleRate: 1.0,
            tracePropagationTargets: ['127.0.0.1', /^https:\/\/account\.mgka\.net/, '127.0.0.1'],
        });

        window.__sentry_initialized__ = true;
        console.warn('Sentry initalised!');
    } else {
        // already initialised, return the existing Sentry instance
        console.warn('Sentry already initialised');
    }

    // return the Sentry instance
    return Sentry;
}
