var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Configurations for modal behavior and styling.
 */
const isOpenClass = "modal-is-open";
const openingClass = "modal-is-opening";
const closingClass = "modal-is-closing";
const animationDuration = 400; // ms
let visibleModal = null;
/**
 * Class representing a modal dialog.
 */
export class Modal {
    /**
     * Creates an instance of the Modal class.
     * @param options - Options for configuring the modal.
     */
    constructor(options) {
        var _a, _b, _c, _d;
        this.closeOnClickOutside = (_a = options.closeOnClickOutside) !== null && _a !== void 0 ? _a : false;
        this.closeOnEscape = (_b = options.closeOnEscape) !== null && _b !== void 0 ? _b : false;
        this.closeOnEnter = (_c = options.closeOnEnter) !== null && _c !== void 0 ? _c : false;
        this.removeOnClose = (_d = options.removeOnClose) !== null && _d !== void 0 ? _d : true;
        this.modalElement = document.createElement("dialog");
        this.modalElement.setAttribute("style", "width: max-content;");
        const article = document.createElement("article");
        article.style.minWidth = "60%";
        article.style.margin = "auto";
        // Add Close Button
        const closeButton = document.createElement("a");
        closeButton.href = "#close";
        closeButton.setAttribute("aria-label", "Close");
        closeButton.className = "close";
        closeButton.addEventListener("click", (event) => {
            event.preventDefault();
            this.close();
        });
        article.appendChild(closeButton);
        // Add Title
        if (options.title) {
            const title = document.createElement("h3");
            title.textContent = options.title;
            article.appendChild(title);
        }
        // Add Content
        this.contentElement = document.createElement("div");
        if (options.content) {
            if (typeof options.content === "string") {
                this.contentElement.innerHTML = options.content;
            }
            else {
                this.contentElement.appendChild(options.content);
            }
        }
        article.appendChild(this.contentElement);
        // Add Actions (buttons, links, etc.)
        if (options.actions) {
            const actionsContainer = document.createElement("footer");
            options.actions.forEach((action) => {
                const button = document.createElement("a");
                button.href = "#";
                button.textContent = action.label;
                button.setAttribute("role", "button");
                button.style.display = "inline-block";
                button.style.padding = "10px 15px";
                button.style.margin = "5px";
                if (action.color) {
                    button.style.backgroundColor = action.color;
                    button.style.borderColor = action.color;
                    button.style.color = "#fff"; // Assuming white text for colored buttons
                }
                button.addEventListener("click", (event) => __awaiter(this, void 0, void 0, function* () {
                    event.preventDefault();
                    if (action.animateWhileRunning) {
                        button.setAttribute("aria-busy", "true");
                    }
                    try {
                        yield action.action();
                    }
                    finally {
                        if (action.animateWhileRunning) {
                            button.removeAttribute("aria-busy");
                        }
                    }
                }));
                actionsContainer.appendChild(button);
            });
            article.appendChild(actionsContainer);
        }
        this.modalElement.appendChild(article);
        document.body.appendChild(this.modalElement);
        if (this.closeOnClickOutside) {
            document.addEventListener("click", (event) => {
                if (event.target !== this.modalElement) {
                    this.close();
                }
            });
        }
        if (this.closeOnEscape) {
            document.addEventListener("keydown", (event) => {
                if (event.key === "Escape") {
                    this.close();
                }
            });
        }
        if (this.closeOnEnter) {
            document.addEventListener("keydown", (event) => {
                if (event.key === "Enter") {
                    this.close();
                }
            });
        }
    }
    /**
     * Opens the modal by setting the appropriate classes and attributes.
     */
    open() {
        openModal(this.modalElement);
    }
    /**
     * Closes the modal by removing the appropriate classes and attributes, and removing it from the DOM.
     */
    close() {
        closeModal(this.modalElement, this.removeOnClose);
    }
    /**
     * Sets the content of the modal.
     * @param content - The content to set, either as a string or an HTML element.
     */
    setContent(content) {
        this.contentElement.innerHTML = "";
        if (typeof content === "string") {
            this.contentElement.innerHTML = content;
        }
        else {
            this.contentElement.appendChild(content);
        }
    }
    /**
     * Toggles the aria-busy attribute of the specified button.
     * @param buttonLabel - The label of the button whose aria-busy attribute should be toggled.
     * @param isBusy - Whether the button should be set to aria-busy or not.
     */
    animate(buttonLabel, isBusy) {
        const buttons = this.modalElement.querySelectorAll('a[role="button"]');
        buttons.forEach((button) => {
            if (button.textContent === buttonLabel) {
                if (isBusy) {
                    button.setAttribute("aria-busy", "true");
                }
                else {
                    button.removeAttribute("aria-busy");
                }
            }
        });
    }
}
/**
 * Checks if the modal is currently open.
 * @param modal - The modal to check.
 * @returns True if the modal is open, otherwise false.
 */
export const isModalOpen = (modal) => {
    return modal.hasAttribute("open") && modal.getAttribute("open") !== "false";
};
/**
 * Opens the specified modal.
 * @param modal - The modal to open.
 */
export const openModal = (modal) => {
    if (isScrollbarVisible()) {
        document.documentElement.style.setProperty("--scrollbar-width", `${getScrollbarWidth()}px`);
    }
    document.documentElement.classList.add(isOpenClass, openingClass);
    setTimeout(() => {
        visibleModal = modal;
        document.documentElement.classList.remove(openingClass);
    }, animationDuration);
    modal.setAttribute("open", "true");
};
/**
 * Closes the specified modal.
 * @param modal - The modal to close and remove from the DOM.
 */
export const closeModal = (modal, removeOnClose) => {
    visibleModal = null;
    document.documentElement.classList.add(closingClass);
    setTimeout(() => {
        var _a;
        document.documentElement.classList.remove(closingClass, isOpenClass);
        document.documentElement.style.removeProperty("--scrollbar-width");
        modal.removeAttribute("open");
        if (removeOnClose) {
            // Remove from the DOM
            (_a = modal.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(modal);
        }
    }, animationDuration);
};
/**
 * Calculates the width of the scrollbar.
 * @returns The width of the scrollbar in pixels.
 */
export const getScrollbarWidth = () => {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    document.body.appendChild(outer);
    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);
    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    // Removing temporary elements from the DOM
    if (outer.parentNode) {
        outer.parentNode.removeChild(outer);
    }
    return scrollbarWidth;
};
/**
 * Checks if the scrollbar is currently visible.
 * @returns True if the scrollbar is visible, otherwise false.
 */
export const isScrollbarVisible = () => {
    return document.body.scrollHeight > window.innerHeight;
};
/**
 * The currently visible modal.
 */
export { visibleModal };
